// @flow

import { createSlice } from "@reduxjs/toolkit";

import {
  classificationLegend,
  classificationLegend2024,
  changeLegend,
  sandBarLegend,
} from "../config";

const initialState = {
  classification: classificationLegend,
  classification2024: classificationLegend2024,
  change: changeLegend,
  "sand-bar": sandBarLegend,
};

function setLegend(state, action) {
  const { id, legend } = action;
  state[id] = legend;
}

const legendDuck = createSlice({
  name: "legend",
  initialState,
  reducers: {
    setLegend,
  },
});

export default legendDuck;
